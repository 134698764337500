import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-reset-link',
  templateUrl: './reset-link.component.html',
  styleUrls: ['./reset-link.component.css']
})
export class ResetLinkComponent implements OnInit {

  constructor(
    private dataService : DataService
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Reset Link')
  }

  onSubmit(formData) {

    if(formData.value.email.trim() == '') return;

    this.dataService.__post('/reset-link', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Reset link sent successfully. Please check your email account')
      },
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )
   
  }

}
