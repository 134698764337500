import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  isLoading : boolean = false
  id: any = false

  constructor(
    private dataService : DataService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.dataService.setTitle('Forgot Password')
    this.route.params.subscribe(
      (params) => {
        this.id = params['id']
      }
    )
  }

  onSubmit(formData) {

    if(formData.value.password != formData.value.confirmPassword) {
      this.dataService.showAlert('error', 'Error', 'Password and confirm password not match')
      return;
    }

    if(!this.id) return;

    formData.value.id = this.id

    this.dataService.__post('/forgot-password', formData.value)
    .subscribe(
      (success) => {
        this.dataService.showAlert('success', 'Success', 'Password forgot successfully')
        this.router.navigateByUrl('/login')
      }, 
      (error) => {
        this.dataService.showAlert('error', 'Error', error)
      }
    )

    

  }

}
